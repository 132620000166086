<template>
    <div>
      <Toolbar class="mb-4">
        <template #left>
         
        </template>
  
        <template #right>
          <!-- <Button icon="pi pi-cog" class="p-button-basic ml-2" /> -->
          <Button
            icon="pi pi-download"
            class="p-button-help"
            @click="exportCSV($event)"
          />
          <!-- <Button icon="pi pi-search" class="ml-2" />
          <Button icon="pi pi-times" class="p-button-danger" /> -->
        </template>
      </Toolbar>
  
      <DataTable
        ref="dt"
        :value="list"
        :paginator="true"
        class="p-datatable-customers"
        :rows="10"
        dataKey="id"
        :rowHover="true"
        :selection.sync="selectedItems"
        :loading="loading"
        :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50, 100]"
        currentPageReportTemplate="show {first} to {last} from {totalRecords}"
      >
        <template #header>
          <div class="table-header">
            Production Aufführen
  
            <div style="text-align: left">
              <!-- <MultiSelect
                :value="selectedColumns"
                :options="columns"
                optionLabel="header"
                @input="onToggle"
                placeholder="Select Columns"
                style="width: 20em"
              /> -->
            </div>
          </div>
        </template>
        <template #empty>Keine Daten</template>
        <template #loading>Daten werden geladen, bitte warten... </template>
        <Column
          filterMatchMode="contains"
          field="id"
          header="ID"
          :sortable="true"
        >
          <template #filter>
            <InputText
              type="number"
              v-model="filters['id']"
              class="p-column-filter"
              placeholder="Search By ID"
            />
          </template>
        </Column>

        <Column
          filterMatchMode="contains"
          field="code"
          header="S.N"
          :sortable="true"
        >
          <template #filter>
            <InputText
              v-model="filters['code']"
              class="p-column-filter"
              placeholder="Search By S.N"
              filterMatchMode="contains"
            />
          </template>
          <template #body="slotProps">
            {{ slotProps.data.code }}
          </template>
        </Column>
        <Column
          filterMatchMode="contains"
          field="productName"
          header="Name"
          :sortable="true"
        >
          <template #filter>
            <InputText
              v-model="filters['productName']"
              class="p-column-filter"
              placeholder="Search By Name"
              filterMatchMode="contains"
            />
          </template>
         
        </Column>
  
        <Column
          filterMatchMode="contains"
          field="totalSold"
          header="Total Sold Count"
          :sortable="true"
        >
          <template #filter>
            <InputText
              v-model="filters['totalSold']"
              class="p-column-filter"
              placeholder="Search By Total Sold"
              filterMatchMode="contains"
            />
          </template>
        </Column>
  
        <Column
          filterMatchMode="contains"
          field="totalSales"
          header="Total Sales"
          :sortable="true"
        >
          <template #filter>
            <InputText
              v-model="filters['totalSales']"
              class="p-column-filter"
              placeholder="Search By Total Sales"
              filterMatchMode="contains"
            />
          </template>
          <template #body="slotProps"> {{ slotProps.data.totalSales }} € </template>
        </Column>
  
       
        
      </DataTable>
    </div>
  </template>
<script>
export default {
    data(){
        return {
            list:[]
        }
    },
    created(){
        this.$http.post('products/carts_items').then(res=>{
            this.list= res.data.data
        })
    }
}
</script>